const Settings = {
  navTheme: 'light', //was 'light', then 'dark'
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: '', //'eGaranție1'
  pwa: false,
  logo: '/egar-logo-dark.svg', // https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg
  iconfontUrl: '',
};
export default Settings;
