export default {
  'app.settings.menuMap.basic': 'Setări de bază',
  'app.settings.menuMap.security': 'Setări de securitate',
  'app.settings.menuMap.binding': 'Legare cont',
  'app.settings.menuMap.notification': 'Notificare mesaj nou',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Schimbă avatarul',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Vă rugăm să introduceți email-ul!',
  'app.settings.basic.nickname': 'Poreclă',
  'app.settings.basic.nickname-message': 'Vă rugăm să introduceți porecla dvs.!',
  'app.settings.basic.profile': 'Profil personal',
  'app.settings.basic.profile-message': 'Vă rugăm să introduceți profilul dvs. personal!',
  'app.settings.basic.profile-placeholder': 'O scurtă introducere despre tine',
  'app.settings.basic.country': 'Țară / Regiune',
  'app.settings.basic.country-message': 'Vă rugăm să introduceți țara dvs.!',
  'app.settings.basic.geographic': 'Provincie sau oraș',
  'app.settings.basic.geographic-message': 'Vă rugăm să introduceți informațiile geografice!',
  'app.settings.basic.address': 'Adresa străzii',
  'app.settings.basic.address-message': 'Vă rugăm să introduceți adresa dvs.!',
  'app.settings.basic.phone': 'Număr de telefon',
  'app.settings.basic.phone-message': 'Vă rugăm să introduceți telefonul dvs.!',
  'app.settings.basic.update': 'Actualizați informațiile',
  'app.settings.security.strong': 'Puternic',
  'app.settings.security.medium': 'Mediu',
  'app.settings.security.weak': 'Slab',
  'app.settings.security.password': 'Parola contului',
  'app.settings.security.password-description': 'Puterea curentă a parolei',
  'app.settings.security.phone': 'Telefon de securitate',
  'app.settings.security.phone-description': 'Telefon legat',
  'app.settings.security.question': 'Întrebare de securitate',
  'app.settings.security.question-description':
    'Întrebarea de securitate nu este setată, iar politica de securitate poate proteja eficient contul',
  'app.settings.security.email': 'Email de rezervă',
  'app.settings.security.email-description': 'Email legat',
  'app.settings.security.mfa': 'Dispozitiv MFA',
  'app.settings.security.mfa-description':
    'Dispozitiv MFA neînregistrat, după legare, se poate confirma de două ori',
  'app.settings.security.modify': 'Modificare',
  'app.settings.security.set': 'Setare',
  'app.settings.security.bind': 'Legare',
  'app.settings.binding.taobao': 'Legare Taobao',
  'app.settings.binding.taobao-description': 'Cont Taobao nelegat în prezent',
  'app.settings.binding.alipay': 'Legare Alipay',
  'app.settings.binding.alipay-description': 'Cont Alipay nelegat în prezent',
  'app.settings.binding.dingding': 'Legare DingTalk',
  'app.settings.binding.dingding-description': 'Cont DingTalk nelegat în prezent',
  'app.settings.binding.bind': 'Legare',
  'app.settings.notification.password': 'Parolă cont',
  'app.settings.notification.password-description':
    'Mesajele de la alți utilizatori vor fi notificate sub forma unei scrisori de stație',
  'app.settings.notification.messages': 'Mesaje sistem',
  'app.settings.notification.messages-description':
    'Mesajele sistemului vor fi notificate sub forma unei scrisori de stație',
  'app.settings.notification.todo': 'Notificare To-do',
  'app.settings.notification.todo-description':
    'Lista de activități va fi notificată sub forma unei scrisori de la stație',
  'app.settings.open': 'Deschidere',
  'app.settings.close': 'Închidere',
};
