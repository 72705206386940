// @ts-ignore

/* eslint-disable */
/** 获取当前的用户 GET /api/currentUser */
//console.log(request);

import request from 'umi-request';

export async function currentUser(options) {

  //return request('http://localhost:5000/api/auth/currentUser', {
  return request('/api/auth/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}
/** 退出登录接口 POST /api/login/outLogin */

export async function outLogin(options) {
  localStorage.removeItem('auth');
  return request('/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}
/** 登录接口 POST /api/login/account */

// export async function login(body, options) {
//   return request('http://localhost:5000/api/auth/login', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: body,
//     ...(options || {}),
//   });
// }
/** 此处后端没有提供注释 GET /api/notices */

export async function getNotices(options) {
  // return request('/api/notices', {
  //   method: 'GET',
  //   ...(options || {}),
  // });
  return [];
}
/** 获取规则列表 GET /api/rule */

export async function rule(params, options) {
  return request('/api/rule', {
    method: 'GET',
    params: { ...params },
    ...(options || {}),
  });
}
/** 新建规则 PUT /api/rule */

export async function updateRule(options) {
  return request('/api/rule', {
    method: 'PUT',
    ...(options || {}),
  });
}
/** 新建规则 POST /api/rule */

export async function addRule(options) {
  return request('/api/rule', {
    method: 'POST',
    ...(options || {}),
  });
}
/** 删除规则 DELETE /api/rule */

export async function removeRule(options) {
  return request('/api/rule', {
    method: 'DELETE',
    ...(options || {}),
  });
}
