export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  //NEW:
  'menu.forgotpassword': 'Forgot Password',
  'menu.resetpassword': 'Reset Password',
  'menu.activateaccount': 'Activate Account',

  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Basic Form',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',

  //NEW:
  'menu.Users': 'Users',
  'menu.Users.List': 'Users List',
  'menu.Users.New': 'Add User',
  'menu.Users.Update': 'Update User',



  'menu.Records': 'Records',
  'menu.Records.List': 'Records List',
  'menu.Records.New': 'Add Record',
  'menu.Records.Update': 'Update Records',

  'menu.Users.Records': 'Records',
  'menu.Users.Records.List': 'Records List',
  'menu.Users.Records.New': 'Add Record',
  'menu.Users.Records.Update': 'Update Records',


  'menu.Roles': 'Roles',
  'menu.Roles.List': 'Roles List',
  'menu.Roles.New': 'Add Role',
  'menu.Roles.Update': 'Update Role',

  'menu.Users.Roles': 'Roles',
  'menu.Users.Roles.List': 'Roles List',
  'menu.Users.Roles.New': 'Add Role',
  'menu.Users.Roles.Update': 'Update Role',


  'menu.Resources': 'Resources',
  'menu.Resources.List': 'Resources List',
  'menu.Resources.New': 'Add Resource',
  'menu.Resources.Update': 'Update Resource',

  'menu.Users.Resources': 'Resources',
  'menu.Users.Resources.List': 'Resources List',
  'menu.Users.Resources.New': 'Add Resource',
  'menu.Users.Resources.Update': 'Update Resource',


  'menu.Permissions': 'Permissions',
  'menu.Permissions.List': 'Permissions List',
  'menu.Permissions.Manage': 'Manage Permissions',
  'menu.Permissions.New': 'Add Permissions',
  'menu.Permissions.Update': 'Update Permission',

  'menu.Users.Permissions': 'Permissions',
  'menu.Users.Permissions.List': 'Permissions List',
  'menu.Users.Permissions.Manage': 'Manage Permissions',
  'menu.Users.Permissions.New': 'Add Permissions',
  'menu.Users.Permissions.Update': 'Update Permission',



  'menu.Products': 'Products',
  'menu.Products.List': 'Products List',
  'menu.Products.New': 'Add Product',
  'menu.Products.Update': 'Update Product',


  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
