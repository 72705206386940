export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Pagină',
  'menu.login': 'Intră în cont',
  'menu.register': 'Inregistrare',
  'menu.register-result': 'Register Result',
  //NEW:
  'menu.forgotpassword': 'Parolă uitată',
  'menu.resetpassword': 'Resetare parolă',
  'menu.activateaccount': 'Activează contul',

  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Formular de bază',
  'menu.form.step-form': 'Step Form',
  'menu.form.step-form.info': 'Step Form(write transfer information)',
  'menu.form.step-form.confirm': 'Step Form(confirm transfer information)',
  'menu.form.step-form.result': 'Step Form(finished)',
  'menu.form.advanced-form': 'Formular avansat',
  'menu.list': 'List',
  'menu.list.table-list': 'Search Table',
  'menu.list.basic-list': 'Basic List',
  'menu.list.card-list': 'Card List',
  'menu.list.search-list': 'Search List',
  'menu.list.search-list.articles': 'Search List(articles)',
  'menu.list.search-list.projects': 'Search List(projects)',
  'menu.list.search-list.applications': 'Search List(applications)',

  //NEW:
  'menu.Users': 'Utilizatori',
  'menu.Users.List': 'Listă utilizatori',
  'menu.Users.New': 'Adaugă utilizator',
  'menu.Users.New Client': 'Adaugă client',
  'menu.Users.Update': 'Modifică utilizator',

  'menu.Records': 'Inregistrări',
  'menu.Records.List': 'Listă înregistrări',
  'menu.Records.New': 'Adaugă înregistrare',
  'menu.Records.Update': 'Modifică înregistrare',

  'menu.Profile': 'Profilul meu',
  'menu.Profile.Info': 'Detalii Profil',

  'menu.Troubleshooters': 'Depanatori',
  'menu.Troubleshooters.Troubleshooters List': 'Listă depanatori',
  'menu.Stores': 'Magazine',
  'menu.Stores.Stores List': 'Magazine',
  'menu.Sellers': 'Vânzători',
  'menu.Sellers.Sellers List': 'Listă vânzători',

  'menu.Clients': 'Utilizatori',
  'menu.Clients.Clients List': 'Listă clienți',
  'menu.Main Clients': 'Locații',
  'menu.Main Clients.Client locations List': 'Locații',

  'menu.Warranty': 'Garanții',
  'menu.Warranty.List': 'Garanții',
  'menu.Warranty.New': 'Adaugă garanție',
  'menu.Warranty.Update': 'Modifică garanție',

  'menu.Users.Records': 'Inregistrări',
  'menu.Users.Records.List': 'Listă înregistrări',
  'menu.Users.Records.New': 'Adaugă înregistrare',
  'menu.Users.Records.Update': 'Modifică înregistrare',

  'menu.Category': 'Categorii',
  'menu.Category.List': 'Listă categorii',
  'menu.Category.New': 'Adaugă categorie',
  'menu.Category.Update': 'Modifică categorie',

  'menu.Manufacturers': 'Producători',
  'menu.Manufacturers.List': 'Listă producători',
  'menu.Manufacturers.New': 'Adaugă producător',
  'menu.Manufacturers.Update': 'Modifică producător',

  'menu.Companies': 'Companii',
  'menu.Companies.List': 'Listă companii',
  'menu.Companies.New': 'Adaugă companie',
  'menu.Companies.Update': 'Modifică companie',

  'menu.Repair': 'Categorii', //was 'Informatii reparatii'
  'menu.Repair.List': 'Categorii', //was 'Listă categorii produse ce pot fi reparate'
  'menu.Repair.List2': 'Listă produse ce pot fi reparate',
  'menu.Repair.List3': 'Listă produse ce pot fi reparate de la toate service-urile',
  'menu.Repair.New': 'Add new',
  'menu.Repair.Copy info to users': 'Copiază date',
  'menu.Repair.Update': 'Modifică Listă categorii produse ce pot fi reparate',

  'menu.Child users': 'Locații',
  'menu.Child users.Service Locations': 'Locații',
  'menu.Child users.Copy info to users': 'Copiază date',

  'menu.Roles': 'Roluri',
  'menu.Roles.List': 'Listă roluri',
  'menu.Roles.New': 'Adaugă rol',
  'menu.Roles.Update': 'Modifică rol',

  'menu.Users.Roles': 'Roluri',
  'menu.Users.Roles.List': 'Listă roluri',
  'menu.Users.Roles.New': 'Adaugă rol',
  'menu.Users.Roles.Update': 'Modifică rol',


  'menu.Resources': 'Resurse',
  'menu.Resources.List': 'Listă resurse',
  'menu.Resources.New': 'Adaugă resursa',
  'menu.Resources.Update': 'Modifică resursa',

  'menu.Users.Resources': 'Resurse',
  'menu.Users.Resources.List': 'Listă resurse',
  'menu.Users.Resources.New': 'Adaugă resursa',
  'menu.Users.Resources.Update': 'Modifică resursa',


  'menu.Permissions': 'Permisiuni',
  'menu.Permissions.List': 'Listă permisiuni',
  'menu.Permissions.Manage': 'Manage permisiuni',
  'menu.Permissions.New': 'Adaugă permisiune',
  'menu.Permissions.Update': 'Modifică permisiune',

  'menu.Users.Permissions': 'Permisiuni',
  'menu.Users.Permissions.List': 'Listă permisiuni',
  'menu.Users.Permissions.Manage': 'Manage permisiuni',
  'menu.Users.Permissions.New': 'Adaugă permisiune',
  'menu.Users.Permissions.Update': 'Modifică permisiune',

  'menu.Users.Service Providers List': 'Provideri service',
  'menu.Users.Resellers List': 'Listă companii',
  'menu.Users.Super Clients List': 'Listă clienți principali',
  'menu.Service entries': 'Intrari service',
  'menu.Service entries.List': 'Listă intrari service',
  'menu.Service entries.New': 'Adaugă intrare service',
  'menu.Service entries.Update': 'Modifică intrare service',

  'menu.Products List': 'Listă produse',
  'menu.Client Products': 'Produse',

  'menu.ServiceRequests': 'Cereri service',
  'menu.ServiceRequests.List': 'Listă cereri service',
  'menu.ServiceRequests.New': 'Adaugă cerere service',
  'menu.ServiceRequests.Update': 'Modifică cereri service',

  'menu.Products': 'Produse',
  'menu.Products.List': 'Listă Produse',
  'menu.Products.New': 'Adaugă Produs',
  'menu.Products.Update': 'Modifică Produs',

  'menu.My products': 'Produsele mele',
  'menu.My products.List': 'Produsele mele',
  'menu.My products.Tag Products': 'Produsele mele',
  'menu.My products.New': 'Adaugă Produs',
  'menu.My products.Update': 'Modifică Produs',

  'menu.Store carts': 'Listă vânzari',
  'menu.Store carts.List': 'Listă vânzari',
  'menu.Store carts.List1': 'Listă vânzari',

  'menu.Sales': 'Vânzări',
  'menu.Sales.List': 'Listă vânzări',

  'menu.Tags': 'Etichete',
  'menu.Tags.List': 'Etichete',

  'menu.privacy': 'Politică de confidențialitate',
  'menu.terms': 'Termeni și condiții',
  'menu.activate-email': 'Activează contul',
  'menu.Settings': 'Setări',
  'menu.Settings.Info': 'Setări info',

  'menu.topMenu.profile': 'Profilul meu',
  'menu.topMenu.settings': 'Setări',
  'menu.topMenu.logout': 'Deconectează-te',

  'Files': 'Fișiere',

  'menu.profile': 'Profilul meu',
  'menu.profile.basic': 'Profil de bază',
  'menu.profile.advanced': 'Profil avansat',
  'menu.result': 'Rezultat',
  'menu.result.success': 'Sucess',
  'menu.result.fail': 'Eroare',
  'menu.exception': 'Excepție',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Cont',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Deconectează-te',
  'menu.editor': 'Graphic Editor',
  'menu.editor.flow': 'Flow Editor',
  'menu.editor.mind': 'Mind Editor',
  'menu.editor.koni': 'Koni Editor',
};
