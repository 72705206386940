// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/eDGar/repo/ANTD/client-pro/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/home/eDGar/repo/ANTD/client-pro/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/home/eDGar/repo/ANTD/client-pro/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/home/eDGar/repo/ANTD/client-pro/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/home/eDGar/repo/ANTD/client-pro/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/home/eDGar/repo/ANTD/client-pro/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1676644892000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/home/eDGar/repo/ANTD/client-pro/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1676644892000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user/landing",
            "name": "eGaranție",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__landing' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/landing'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/login",
            "layout": false,
            "name": "login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user",
            "redirect": "/user/login",
            "exact": true
          },
          {
            "name": "register-result",
            "icon": "smile",
            "path": "/user/register-result",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register-result' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/register-result'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "register",
            "icon": "smile",
            "path": "/user/register",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__register' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/register'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/forgotpassword",
            "layout": false,
            "name": "forgotpassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__forgotpassword' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/forgotpassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/resetpassword",
            "layout": false,
            "name": "resetpassword",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__resetpassword' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/resetpassword'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/activateaccount",
            "layout": false,
            "name": "activateaccount",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__activateaccount' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/activateaccount'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/activate-email",
            "layout": false,
            "name": "activate-email",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__activate-email' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/activate-email'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/user/delete-social-account",
            "layout": false,
            "name": "delete-social-account",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__delete-social-account' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/delete-social-account'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/products",
        "icon": "setting",
        "name": "Products",
        "routes": [
          {
            "path": "/products",
            "redirect": "/products/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/products/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product__product-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/product/product-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "icon": "smile",
            "path": "/products/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product__product-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/product/product-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/products/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__product__product-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/product/product-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/records",
        "icon": "setting",
        "name": "Records",
        "routes": [
          {
            "path": "/records",
            "redirect": "/records/list",
            "id": "123",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/records/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__record__record-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/record/record-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/records/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__record__record-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/record/record-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/records/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__record__record-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/record/record-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/warranty",
        "icon": "fileDone",
        "name": "Warranty",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/warranty",
            "redirect": "/warranty/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/warranty/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__warranty__warranty-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/warranty/warranty-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/warranty/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__warranty__warranty-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/warranty/warranty-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/warranty/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__warranty__warranty-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/warranty/warranty-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/category",
        "icon": "setting",
        "name": "Category",
        "routes": [
          {
            "path": "/category",
            "redirect": "/category/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/category/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__category__category-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/category/category-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/category/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__category__category-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/category/category-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/category/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__category__category-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/category/category-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/manufacturer",
        "icon": "setting",
        "name": "Manufacturers",
        "routes": [
          {
            "path": "/manufacturer",
            "redirect": "/manufacturer/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/manufacturer/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manufacturer__manufacturer-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/manufacturer/manufacturer-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/manufacturer/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manufacturer__manufacturer-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/manufacturer/manufacturer-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/manufacturer/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__manufacturer__manufacturer-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/manufacturer/manufacturer-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/service-entry",
        "icon": "group",
        "name": "Service entries",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/service-entry",
            "redirect": "/service-entry/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/service-entry/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service-entry__service-entry-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/service-entry/service-entry-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/service-entry/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service-entry__service-entry-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/service-entry/service-entry-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/service-entry/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service-entry__service-entry-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/service-entry/service-entry-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/repair",
        "icon": "folder",
        "name": "Repair",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/repair",
            "redirect": "/repair/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/repair/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__repair__repair-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/repair/repair-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "List2",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/repair/products-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__repair__repair-products-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/repair/repair-products-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "List3",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/repair/all-products-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__repair__repair-all-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/repair/repair-all-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/repair/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__repair__repair-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/repair/repair-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/repair/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__repair__repair-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/repair/repair-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/service-locations",
        "icon": "shop",
        "name": "Child users",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/service-locations",
            "redirect": "/service-locations/list",
            "exact": true
          },
          {
            "name": "Service Locations",
            "icon": "smile",
            "path": "/service-locations/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__service-locations-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/service-locations-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Service Location",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/service-locations/service-location/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__service-location' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/service-location'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/troubleshooters",
        "icon": "tool",
        "name": "Troubleshooters",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/troubleshooters",
            "redirect": "/troubleshooters/list",
            "exact": true
          },
          {
            "name": "Troubleshooters List",
            "icon": "smile",
            "path": "/troubleshooters/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__troubleshooters-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/troubleshooters-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/service-entries",
        "icon": "group",
        "name": "Service entries",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/service-entries",
            "redirect": "/service-entries/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/service-entries/list/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service-entry__service-entries' */'/home/eDGar/repo/ANTD/client-pro/src/pages/service-entry/service-entries'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/service-entries/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service-entry__service-entries' */'/home/eDGar/repo/ANTD/client-pro/src/pages/service-entry/service-entries'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/stores",
        "icon": "shop",
        "name": "Stores",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/stores",
            "redirect": "/stores/list",
            "exact": true
          },
          {
            "name": "Stores List",
            "icon": "smile",
            "path": "/stores/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__stores-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/stores-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Store",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/stores/store/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__store' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/store'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/sellers",
        "icon": "team",
        "name": "Sellers",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/sellers",
            "redirect": "/sellers/list",
            "exact": true
          },
          {
            "name": "Sellers List",
            "icon": "smile",
            "path": "/sellers/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__sellers-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/sellers-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/store-carts",
        "icon": "group",
        "name": "Store carts",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/store-carts",
            "redirect": "/store-carts/list",
            "exact": true
          },
          {
            "name": "List1",
            "icon": "smile",
            "path": "/store-carts/list/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cart__store-carts' */'/home/eDGar/repo/ANTD/client-pro/src/pages/cart/store-carts'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/store-carts/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cart__store-carts' */'/home/eDGar/repo/ANTD/client-pro/src/pages/cart/store-carts'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/client-locations",
        "icon": "shop",
        "name": "Main Clients",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/client-locations",
            "redirect": "/client-locations/list",
            "exact": true
          },
          {
            "name": "Client locations List",
            "icon": "smile",
            "path": "/client-locations/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__client-locations-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/client-locations-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Store",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/client-locations/client-location/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__client-location' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/client-location'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/clients",
        "icon": "shop",
        "name": "Clients",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/clients",
            "redirect": "/clients/list",
            "exact": true
          },
          {
            "name": "Clients List",
            "icon": "smile",
            "path": "/clients/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__clients-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/clients-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/client-products",
        "icon": "unorderedList",
        "name": "Client Products",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/client-products",
            "redirect": "/client-products/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/client-products/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__client-products-page' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/client-products-page'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/client-products/list/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__child-users__client-products-page' */'/home/eDGar/repo/ANTD/client-pro/src/pages/child-users/client-products-page'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/client-product",
        "icon": "unorderedList",
        "name": "My products",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/client-product",
            "redirect": "/client-product/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/client-product/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-product__client-product-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/client-product/client-product-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Tag Products",
            "icon": "smile",
            "path": "/client-product/product-tag/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-product__products-with-tag' */'/home/eDGar/repo/ANTD/client-pro/src/pages/client-product/products-with-tag'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/client-product/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-product__client-product-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/client-product/client-product-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/client-product/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__client-product__client-product-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/client-product/client-product-update'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/eDGar/repo/ANTD/client-pro/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/cart",
        "icon": "group",
        "name": "Sales",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/cart",
            "redirect": "/cart/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/cart/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__cart__cart-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/cart/cart-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/tags",
        "icon": "tags",
        "name": "Tags",
        "hideChildrenInMenu": true,
        "routes": [
          {
            "path": "/tags",
            "redirect": "/tags/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/tags/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__tag__tag-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/tag/tag-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/company",
        "icon": "setting",
        "name": "Companies",
        "routes": [
          {
            "path": "/company",
            "redirect": "/company/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/company/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__company-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/company/company-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/company/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__company-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/company/company-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/company/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__company__company-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/company/company-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/users",
        "icon": "setting",
        "name": "Users",
        "routes": [
          {
            "path": "/users",
            "redirect": "/users/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/users/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__user-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/user-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New Client",
            "icon": "smile",
            "path": "/users/add-client",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__new-client' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/new-client'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Super Clients List",
            "icon": "smile",
            "path": "/users/super-clients-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__super-clients-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/super-clients-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/users/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__user-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/user-update'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Resellers List",
            "icon": "smile",
            "path": "/users/companies-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__companies-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/companies-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Service Providers List",
            "icon": "smile",
            "path": "/users/service-providers-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__service-providers-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/service-providers-list'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "true",
        "path": "/profile",
        "icon": "setting",
        "name": "Profile",
        "hideInMenu": true,
        "routes": [
          {
            "name": "Info",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__profile' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/profile'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/roles",
        "icon": "setting",
        "name": "Roles",
        "routes": [
          {
            "path": "/roles",
            "redirect": "/roles/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/roles/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__role__role-list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/role/role-list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "icon": "smile",
            "path": "/roles/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__role__role-entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/role/role-entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/roles/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__role__role-update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/role/role-update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/resources",
        "icon": "setting",
        "name": "Resources",
        "routes": [
          {
            "path": "/resources",
            "redirect": "/resources/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/resources/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resource__list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/resource/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "icon": "smile",
            "path": "/resources/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resource__entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/resource/entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/resources/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__resource__update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/resource/update'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "access": "canAccess",
        "path": "/permissions",
        "icon": "setting",
        "name": "Permissions",
        "routes": [
          {
            "path": "/permissions",
            "redirect": "/permissions/list",
            "exact": true
          },
          {
            "name": "List",
            "icon": "smile",
            "path": "/permissions/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__permission__list' */'/home/eDGar/repo/ANTD/client-pro/src/pages/permission/list'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "New",
            "icon": "smile",
            "path": "/permissions/new",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__permission__entry' */'/home/eDGar/repo/ANTD/client-pro/src/pages/permission/entry'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Update",
            "hideInMenu": true,
            "icon": "smile",
            "path": "/permissions/edit/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__permission__update' */'/home/eDGar/repo/ANTD/client-pro/src/pages/permission/update'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "Manage",
            "icon": "smile",
            "path": "/permissions/manage",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__permission__manage' */'/home/eDGar/repo/ANTD/client-pro/src/pages/permission/manage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/profile",
        "name": "profile",
        "icon": "profile",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/profile",
            "redirect": "/profile/basic",
            "exact": true
          },
          {
            "name": "basic",
            "icon": "smile",
            "path": "/profile/basic",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__profile__basic' */'/home/eDGar/repo/ANTD/client-pro/src/pages/profile/basic'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "advanced",
            "icon": "smile",
            "path": "/profile/advanced",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__profile__advanced' */'/home/eDGar/repo/ANTD/client-pro/src/pages/profile/advanced'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "hideInMenu": true,
        "name": "exception",
        "icon": "warning",
        "path": "/exception",
        "routes": [
          {
            "path": "/exception/403",
            "redirect": "/exception/exception/403",
            "exact": true
          },
          {
            "name": "403",
            "icon": "smile",
            "path": "/exception/403",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__403' */'/home/eDGar/repo/ANTD/client-pro/src/pages/exception/403'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "404",
            "icon": "smile",
            "path": "/exception/404",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__404' */'/home/eDGar/repo/ANTD/client-pro/src/pages/exception/404'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "500",
            "icon": "smile",
            "path": "/exception/500",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__500' */'/home/eDGar/repo/ANTD/client-pro/src/pages/exception/500'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/home2",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/home2",
        "exact": true
      },
      {
        "hideInMenu": true,
        "path": "/home2",
        "name": "Home2",
        "icon": "smile",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__home2' */'/home/eDGar/repo/ANTD/client-pro/src/pages/user/home2'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "hideInMenu": true,
        "path": "/privacy",
        "name": "privacy",
        "icon": "smile",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__public__privacy-policy' */'/home/eDGar/repo/ANTD/client-pro/src/pages/public/privacy-policy'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "hideInMenu": true,
        "path": "/terms",
        "name": "terms",
        "icon": "smile",
        "layout": false,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__public__terms' */'/home/eDGar/repo/ANTD/client-pro/src/pages/public/terms'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exception__404' */'/home/eDGar/repo/ANTD/client-pro/src/pages/exception/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
