export default {
  'pages.layouts.userLayout.title': 'Gestionare garanții',
  'pages.login.accountLogin.tab': 'Intră în cont',
  'pages.login.accountLogin.errorMessage': 'Nume utilizator sau parolă greșite',
  'pages.login.failure': 'Eroare acces cont',
  'pages.login.success': 'Ați intrat în cont!',

  //NEW:
  'pages.login.register': 'Înregistrare',

  'pages.login.username.placeholder': 'Nume utilizator',
  'pages.login.username.required': 'Introduceți username sau email!',
  'pages.login.password.placeholder': 'Parola',
  'pages.login.password.required': 'Introduceți parola!',
  'pages.login.phoneNumber.placeholder': 'Număr telefon',
  'pages.login.phoneNumber.required': 'Introduceți numărul de telefon!',
  'pages.login.phoneNumber.invalid': 'Număr telefon invalid!',
  'pages.login.rememberMe': 'Salvează datele de logare',
  'pages.login.forgotPassword': 'Ai uitat parola?',
  'pages.login.submit': 'Login',

  'pages.register.firstName': 'Prenumele',
  'pages.register.lastName': 'Numele',
  'pages.register.username': 'Nume utilizator',

  'pages.form.submit': 'Salvează',

  'pages.category.addNewButton': 'Adaugă categorie',

  'pages.recordUpdate.exitService': 'Ieșire din service',
  'pages.recordUpdate.addToService': 'Adaugă în service',

  'tables.columns.name': 'Nume',
  'tables.columns.username': 'Utilizator',
  'tables.columns.usernameLong': 'Nume de utilizator',
  'tables.columns.phone': 'Număr Telefon',
  'tables.columns.phoneShort': 'Telefon',
  'tables.columns.email': 'Email',
  'tables.columns.role': 'Tip cont',
  'tables.columns.description': 'Descriere',
  'tables.columns.address': 'Adresă',
  'tables.columns.actions': 'Acțiuni',
  'tables.columns.tag': 'Etichetă', // not used anymore
  'tables.columns.totalWithTag': 'Total Produse',

  'pages.cardForm.submit': 'Salvează',
  'pages.tableForm.submit': 'Salvează',

  // Log in/sign up:
  'pages.login.facebook': 'Loghează-te cu Facebook',

  // Totals:
  'tables.totals.result': 'rezultat',
  'tables.totals.results': 'rezultate',
  'tables.totals.serviceEntries': 'produse',
  'tables.totals.troubleshooters': 'depanatori',
  'tables.totals.sellers': 'vânzători',
  'tables.totals.stores': 'magazine',
  'tables.totals.sales': 'vânzari',
  'tables.totals.products': 'produse',
  'tables.totals.records': 'produse',
  'tables.totals.tags': 'etichete',
  'tables.totals.warranties': 'garanții',
  'tables.totals.clients': 'clienți',
  'tables.totals.client locations': 'locații client',

  // Pop-ups:
  'popups.delete.title': 'Șterge',
  'popups.delete.content': 'Această acțiune este permanentă și nu poate fi anulată.',
  'popups.delete.ok': 'Da, șterge',
  'popups.delete.no': 'Anulează',
  'popups.delete.buttonName': 'Șterge',
  'popups.qrScanner.title': 'Scanzează cod QR',
  'popups.showQrCode.title': 'Cod QR',
  'popups.deleteAccount.warningInfo': 'Această acțiune este permanentă și nu vei putea utiliza acest cont în viitor. Confirmă cu parola.',
  'popups.deleteSocialAccount.warningInfo': 'Contul va fi șters definitiv! Va trebui să confirmați pe email.',
  'popups.changeEmail.title': 'Editează email',

  // Search forms - generic:
  'pages.searchForm.any': 'Caută',
  'pages.searchForm.name': 'Nume',
  'pages.searchForm.search': 'Caută',
  'pages.searchForm.clear': 'Resetează',
  // Search forms - my products:
  'pages.searchForm.productName': 'Caută nume produs',
  // Search forms - service entries:
  'pages.searchForm.clientName': 'Client',
  'pages.searchForm.clientName.placeholder': 'Caută', //'Caută nume client'
  'pages.searchForm.recordName': 'Produs',
  'pages.searchForm.recordName.placeholder': 'Caută nume produs',
  'pages.searchForm.serviceEntryDateRange': 'Dată intrare',
  'pages.searchForm.troubleshooter': 'Depanator',
  'pages.searchForm.troubleshooter.placeholder': 'Selectează depanator',
  'pages.searchForm.clientUser.placeholder': 'Selectează utilizator',
  'pages.searchForm.clientUser': 'Utilizator',

  // Search forms - Listă vanzari:
  'pages.searchForm.soldDateRange': 'Dată vânzare',
  'pages.searchForm.seller': 'Vânzător',
  'pages.searchForm.seller.placeholder': 'Selectează vânzător',

  // Search forms - warranties:
  'pages.searchForm.warrantyName': 'Caută nume garanție',

  // Table columns - generic:
  'tables.columnNames.thumbnail': 'Imagine',
  'tables.columnNames.productName': 'Nume produs',
  'tables.columnNames.price': 'Preț',
  'tables.columnNames.tag': 'Etichetă',
  'tables.columnNames.include': 'Repară',

  // My products:
  'tables.columnNames.warrantyDaysRemaining': 'Zile rămase în garanție',
  'tables.columnNames.ServiceLinks': 'Service',
  'tables.columnNames.ServiceHistory': 'Istoric service',
  'tables.columnNames.Warranties': 'Garanții',
  'tables.columnNames.Warranty': 'Garanție',
  'tables.columnNames.QrCode': 'Cod QR',
  // Tags:
  'tables.columnValues.tags.totalOneTag': 'produs',
  'tables.columnValues.tags.totalMin2Tags': 'produse',
  // Service entries:
  'tables.columnNames.clientName': 'Client',
  'tables.columnNames.boughtDate': 'Dată achiziție',
  'tables.columnNames.entryServiceDate': 'Dată intrare service',
  'tables.columnNames.exitServiceDate': 'Dată ieșire service',
  'tables.columnNames.troubleshooter': 'Depanator',
  // Troubleshooters list:
  'tables.columnNames.troubleshooterName': 'Nume depanator',
  'pages.troubleshooters.add': 'Adaugă depanator',
  'pages.troubleshooters.title': 'Listă depanatori',
  'pages.troubleshooters.title.short': 'Depanatori',
  // Service entries tab
  'pages.serviceLocations.tabs.clientName': 'Client',
  'pages.serviceLocations.tabs.productName': 'Produs',
  'pages.serviceLocations.tabs.entryServiceDate': 'Dată intrare',
  'pages.serviceLocations.tabs.exitServiceDate': 'Dată ieșire',
  // Service locations list
  'pages.serviceLocations.tabs.troubleshooters': 'Depanatori',
  'pages.serviceLocations.tabs.serviceEntries': 'Intrări service',
  'pages.serviceLocations.add': 'Adaugă locație',
  'pages.serviceLocations.title': 'Locații',
  'pages.serviceLocations.serviceLocation': 'Nume',
  'pages.troubleshooters.min2': 'depanatori',
  'pages.troubleshooters.one': 'depanator',
  'pages.troubleshooters.copyInfo': 'Copiază informațiile de reparație', //'Copiaza informatiile {br} de reparatie'
  'pages.troubleshooters.copyInfo.done': 'Categorii reparații copiate!',
  'pages.copyData.title': 'Copiază toate informațiile de reparație la service-uri',
  // Stores list
  'pages.stores.tabs.sellers': 'Vânzători',
  'pages.stores.tabs.carts': 'Listă vânzari',
  // Client locations list
  'pages.clientLocations.add': 'Adaugă locație client',
  'pages.clientLocations.title': 'Locații client',
  'pages.clientLocations.tabs.users': 'Utilizatori',
  'pages.clientLocations.tabs.products': 'Listă produse',
  // Clients list:
  'pages.clientLocations.clientLocation': 'Locație client',
  'pages.clients.add': 'Adaugă client',
  'pages.clients.title': 'Listă clienți',
  'pages.clients.title.short': 'Clienți',
  'pages.clients.min2': 'utilizatori', //was 'clienți'
  'pages.clients.one': 'utilizator', //was 'client'
  'tables.columnNames.productsList': 'Produse client',
  'pages.clientsList.productsListName': 'Listă produse',
  'pages.clientsProducts.title': 'Listă produse',

  // Warranties
  'pages.warranties.title': 'Garanții',
  'pages.warranties.addWarranty': 'Adaugă garanție',
  'pages.warranties.addWarrantyVersion': 'Adaugă versiune',
  'pages.warranties.viewWarranty': 'Detalii garanție',
  'forms.warranties.version': 'Versiune',
  'forms.addWarranty.submitButton': 'Adaugă garanție',
  'forms.addWarrantyVersion.submitButton': 'Adaugă versiune',
  'popups.warranties.add': 'Adaugă garanție',
  'popups.warranties.addVersion': 'Adaugă versiune',
  'popups.warranties.edit': 'Editare garanție',
  'popups.warranties.view': 'Viziualizare garanție',
  'popups.categories.add': 'Adaugă categorie',
  'popups.manufacturers.add': 'Adaugă producător',
  // Warranties versions:
  'popups.warranties.versionList': 'Versiuni garanție',
  'tables.columnValues.warranties.totalOneVersion': 'versiune',
  'tables.columnValues.warranties.totalMin2Versions': 'versiuni',

  // Repair data
  'pages.repaidData.add': 'Adaugă categorie',
  // Service entries
  'pages.serviceHistory.title': 'Istoric service',
  'pages.serviceHistory.addEntry': 'Adaugă în istoric',
  'pages.serviceHistory.addServiceEntry': 'Adaugă intrare service',
  'pages.serviceHistory.editServiceEntry': 'Editare intrare service',
  'pages.serviceHistory.viewServiceEntry': 'Detalii intrare service',
  'pages.viewButton': 'Detalii',

  // Repair list:
  'tables.columnNames.category': 'Categorie',
  'tables.columnNames.manufacturers': 'Producători',
  'tables.columnNames.excludedProducts': 'Produse excluse',

  // Warranties:
  'tables.columnNames.noWarranties': '(fără garanție)',
  'tables.columnNames.warrantyName': 'Nume garanție',
  'tables.columnNames.manufacturer': 'Producător',
  'tables.columnNames.duration': 'Durata garanției',
  'tables.columnNames.applyWarranty': 'Aplică garanție',
  'tables.columnNames.versionsList': 'Listă versiuni',
  'tables.columnNames.version': 'Ultima versiune',
  'tables.columnNames.versionId': 'Număr versiune',
  'tables.columnNames.versionString': 'Cod versiune',
  'tables.columnNames.createdAt': 'Adăugată la',

  // Forms - generic:
  'forms.editForm.name': 'Nume',
  'forms.upload.fileUploadAreaText': 'Apăsați click sau trageți fișier în această zonă pentru a încărca un fișier',
  'forms.takePhoto.buttonText': 'Deschide camera',
  'forms.edit.submitButton': 'Salvează',
  'forms.edit.resetButton': 'Anulează',
  'forms.edit.closeButton': 'Închide',
  'forms.edit.addButton': 'Adaugă',
  'forms.edit.cancelButton': 'Anulează',
  'forms.records.productName': 'Nume produs',

  'forms.deleteAccount.submitButton': 'Da, șterge',

  // Forms - add/edit product:
  'forms.addProduct.submitButton': 'Adaugă Produs',
  'forms.editProduct.productName.placeholder': 'Numele produsului',
  'forms.editProduct.productName.required': 'Te rugăm să introduci numele produsului',
  'forms.editProduct.serialNumber': 'Număr de serie',
  'forms.editProduct.serialNumber.placeholder': 'Numărul de serie (serial number)',
  'forms.editProduct.serialNumber.required': 'Te rugăm să introduci numărul de serie',
  'forms.editProduct.tag': 'Etichetă',
  'forms.editProduct.tag.placeholder': 'Selectează eticheta',
  'forms.editProduct.description': 'Descriere produs',
  'forms.editProduct.description.placeholder': 'Descriere produs (opțional)',
  'forms.editProduct.sellDate': 'Cumpărat la data de',
  'forms.editProduct.sellDate.placeholder': 'Alegeți data când a fost cumpărat produsul',
  'forms.editProduct.sellDate.required': 'Te rugăm să introduci data când a fost cumpărat produsul',
  'forms.editProduct.warrantyDuration': 'Perioada de garanție',
  'forms.editProduct.warrantyDuration.required': 'Alegeți perioada de garanție',
  'forms.editProduct.warrantyOneYear': 'an',
  'forms.editProduct.warrantyMin2Years': 'ani',
  'forms.editProduct.personalRecordFiles': 'Imagini Produs',

  // Forms - add/edit tag:
  'forms.editTag.tagName': 'Numele etichetei',
  'forms.editTag.tagName.required': 'Te rugăm să introduci numele etichetei',
  'forms.editTag.tagName.placeholder': 'Numele etichetei',
  'forms.addTag.submitButton': 'Adaugă eticheta',

  // Forms - edit service entry:
  'forms.editServiceEntry.entryServiceDate': 'Dată intrare service',
  'forms.editServiceEntry.entryServiceDetails': 'Observații intrare service',
  'forms.editServiceEntry.entryServiceDetails.placeholder': 'Vă rugăm introduceți observații intrare în service (opțional)',
  'forms.editServiceEntry.exitServiceDetails': 'Observații ieșire service',
  'forms.editServiceEntry.exitServiceDetails.placeholder': 'Vă rugăm introduceți observații ieșire din service (opțional)',
  'forms.editServiceEntry.serviceFiles': 'Fișiere service',

  // Forms - repair
  'forms.repair.category': 'Categorie',
  'forms.repair.category.placeholder': 'Vă rugăm selectați categoria',
  'forms.manufacturer': 'Producător',
  'forms.manufacturer.placeholder': 'Vă rugăm selectați producătorul',
  'forms.repair.manufacturers': 'Producători',
  'forms.repair.manufacturers.placeholder': 'Vă rugăm selectați producătorii',
  'forms.repair.excludeProducts': 'Produse excluse',
  'forms.repair.includeProducts': 'Produse incluse',
  'forms.repair.allProductsList': 'Listă produse ce pot fi reparate',
  'forms.repair.addNewTitle': 'Adaugă categorie', //was 'Adaugă date reparatii'
  'forms.repairData.addButton': 'Adaugă',

  // Forms - warranty
  'forms.warranty.name': 'Nume garanție',
  'forms.warranty.name.placeholder': 'Vă rugăm introduceți numele garanției',
  'forms.warrantyFiles': 'Fișiere garanție',
  'forms.warranty.duration.error': 'Vă rugăm selectați durata garanției',
  'forms.warranty.addCategoryButton': 'Adaugă categorie',
  'forms.warranty.addManufacturerButton': 'Adaugă producător',
  'forms.warranty.version': 'Versiune',
  'forms.warranty.version.error': 'Vă rugăm introduceți o versiune',

  // Forms - category
  'forms.category.name': 'Nume categorie',
  'forms.category.name.placeholder': 'Numele categoriei',
  'forms.category.name.required': 'Te rugăm să introduci numele categoriei',
  'forms.category.manufacturers': 'Producători',
  'forms.category.manufacturers.placeholder': 'Selectează producători posibili (unul sau mai mulți)',
  'forms.category.parent': 'Părinte',
  'forms.category.parent.placeholder': 'Selectează părintele categoriei',
  'forms.category.description': 'Descriere',
  'forms.category.description.placeholder': 'Descriere categorie (opțional)',

  // Forms - manufacturer
  'forms.manufacturer.name': 'Nume producător',
  'forms.manufacturer.name.placeholder': 'Numele producătorului',
  'forms.manufacturer.name.required': 'Te rugăm să introduci numele producătorului',
  'forms.manufacturer.description': 'Descriere',
  'forms.manufacturer.description.placeholder': 'Descriere producător (opțional)',

  // Profile
  'forms.profile.firstName': 'Prenume',
  'forms.profile.firstName.required': 'Te rugăm să introduci prenumele',
  'forms.profile.firstName.placeholder': 'Prenume',
  'forms.profile.lastName': 'Nume',
  'forms.profile.lastName.required': 'Te rugăm să introduci numele',
  'forms.profile.lastName.placeholder': 'Nume',
  'forms.profile.name': 'Nume',
  'forms.profile.name.required': 'Te rugăm să introduci numele',
  'forms.profile.name.placeholder': 'Nume',
  'forms.profile.username': 'Nume de utilizator',
  'forms.profile.username.required': 'Te rugăm să introduci numele de utilizator',
  'forms.profile.username.placeholder': 'Nume utilizator',
  'forms.profile.phoneNumber': 'Număr telefon',
  'forms.profile.phoneNumber.placeholder': 'Ex: 07xxxxxxxx',
  'forms.profile.phoneNumber.required': 'Te rugăm să introduci numărul de telefon',
  'forms.profile.errors.wrongPhoneNumber': 'Număr telefon invalid',
  'forms.profile.changeEmailButton': 'Schimbă email',
  'forms.profile.infoChangeEmail.p1': 'Activați noul email folosind link-ul primit la: ',
  'forms.profile.infoChangeEmail.p2': '.',

  'forms.profile.address': 'Adresa',
  'forms.profile.address.placeholder': 'Introduceți adresa',
  'forms.profile.city': 'Oraș',
  'forms.profile.city.placeholder': 'Introduceți orașul',
  'forms.profile.county': 'Județ',
  'forms.profile.county.placeholder': 'Introduceți județul',
  'forms.profile.country': 'Țara',
  'forms.profile.country.placeholder': 'Introduceți țara',
  'forms.profile.postalCode': 'Codul poștal',
  'forms.profile.postalCode.placeholder': 'Introduceți codul poștal',
  'forms.profile.schedule': 'Program de lucru',
  'forms.profile.schedule.placeholder': 'Introduceți programul de lucru.\nExemplu: Luni-Vineri: 9:00-17:00',

  // Pages:
  'pages.profile.editPersonalData': 'Editează',
  'pages.profile.personalData.title': 'Date personale',
  'pages.profile.personalData.name': 'Nume',
  'pages.profile.personalData.phone': 'Telefon',
  'pages.profile.personalData.address': 'Adresă',
  'pages.profile.personalData.schedule': 'Program',
  'pages.profile.apiKey.title': 'Cheie API',
  'popups.editPersonalData.title': 'Editează date personale',

  // Client - My products:
  'pages.myProducts.scanQrCode': 'Scanează cod QR',

  // Client - Edit record:
  'pages.editRecord.showQrCode': 'Arată cod QR',

  // Client - Tags:
  'pages.tags.title': 'Etichete',
  'pages.tags.add': 'Adaugă etichetă',
  'pages.tags.popups.addTag.title': 'Adaugă etichetă',
  'pages.tags.popups.editTag.title': 'Editează etichetă',
  'pages.tags.popups.productsWithTag.title': 'Produse cu etichetă',

  // Vânzător
  'pages.cartList.title': 'Listă vânzări',
  'pages.cartList.soldProductsList': 'Produse vândute',
  'pages.cartList.soldProductsListButton': 'Vezi produse vândute',
  'pages.cartList.sellerName': 'Vânzător',
  'pages.cartList.clientName': 'Cumpărator',
  'pages.cartList.totalProducts': 'Total produse vândute',
  'pages.cartList.soldDate': 'Data vânzării',
  'pages.cartList.status.finished': 'Vânzare finalizată',
  'pages.cartList.status.finishedPlusHasOwner': 'Vânzare finalizată', // (cumparator atribuit)
  'pages.cart.title': 'Produse vândute',

  'pages.cartSimpleList.title': 'Produse în coș',
  'pages.cartList.oneProduct': 'produs',
  'pages.cartList.products': 'produse',

  'pages.clientProductList.clientName': 'Utilizator',
  'pages.clientProductList.allTags': 'Toate etichetele',

  // Vânzătorânzători
  'pages.sellers.title': 'Vânzători',
  'pages.sellers.popups.addSeller.title': 'Adaugă vânzător',


  // Magazine
  'pages.stores.title': 'Listă magazine',
  'pages.stores.addStore': 'Adaugă magazin',

  // Clients
  'pages.clientProductsTab.allUsers': 'Toți utilizatorii',

  // Sellers - sold items:
  'pages.cartsTab.allSellers': 'Toți vânzătorii',
  'pages.cartsList.title': 'Vânzări',

  // Troubleshooter - service entries:
  'pages.serviceEntries.title': 'Intrări service',
  'pages.serviceEntries.allTroubleshooters': 'Toți depanatorii',
  'pages.serviceEntries.listName': 'Listă intrări service',
  'pages.serviceEntries.addTroubleshooter': 'Adaugă depanator',

  // Settings
  'pages.settings.changePassword': 'Modifică parola',
  'pages.settings.deleteAccount': 'Șterge cont',

  'pages.changePassword.oldPassword': 'Parola curentă',
  'pages.changePassword.oldPassword.placeholder': 'Parola curentă',
  'pages.changePassword.oldPassword.required': 'Te rugăm să introduci parola curentă',
  'pages.changePassword.newPassword': 'Parola nouă',
  'pages.changePassword.newPassword.placeholder': 'Parola nouă',
  'pages.changePassword.newPassword.required': 'Te rugăm să introduci parola nouă',
  'pages.changePassword.confirmNewPassword': 'Confirma parola nouă',
  'pages.changePassword.confirmNewPassword.placeholder': 'Confirma parola nouă',
  'pages.changePassword.confirmNewPassword.required': 'Te rugăm să reintroduci parola nouă',
  'pages.changePassword.submit': 'Schimbă parola',
  'pages.changePassword.error.tooShort': 'Parola este prea scurtă',
  'pages.changePassword.error.differentPasswords': 'Parolele nu coincid',

  // New account
  'popups.addUser.firstName': 'Prenume',
  'popups.addUser.lastName': 'Nume',
  'popups.addUser.password': 'Parola',
  'popups.addUser.confirmPassword': 'Confirmă parola',
  'popups.addUser.submit': 'Adaugă',
  'popups.addUser.address': 'Adresă',
  'popups.addUser.phone': 'Telefon',
  'popups.addUser.email': 'Email',
  'popups.addUser.email.required': 'Introduceți adresa de email!',
  'popups.addUser.email.error': 'Eroare format adresă de email!',

  // Others:
  'pages.Products List.title': 'Produse',

  'tables.columnNames.description': 'Descriere',
  'tables.columnNames.status': 'Status',
  'tables.columnNames.actions': 'Acțiuni',
  'tables.columnNames.delete': 'Șterge',
  'tables.buttons.delete': 'Șterge',
  'tables.buttons.edit': 'Editează',
  'tables.buttons.addNewProduct': 'Adaugă produs',

  'tables.columnValues.no_category': '(fără categorie)',
  'tables.columnValues.no_manufacturer': '(fără producător)',

  'pages.form.email.placeholder': 'Introduceți adresa de email',
  'pages.form.email.required': 'Introduceți adresa de email!',
  'pages.form.email.type': 'Introduceți o adresă de email validă!',

  // Messages:
  'messages.success.socialAccount.email.deleted': 'Verificați emailul pentru confirmare',
  'messages.success.socialAccount.deleted': 'Contul a fost șters!',
  'messages.success.records.add': 'Produsul a fost adăugat',
  'messages.success.tags.add': 'Eticheta a fost adăugată',
  'messages.success.tags.edit': 'Eticheta a fost salvată',
  'messages.success.serviceEntry.edit': 'Intrarea în service a fost salvată',
  'messages.success.warranties.edit': 'Garanția a fost salvată',
  'messages.success.warranties.add': 'Garanția a fost adăugată',
  'messages.success.warrantyVersion.add': 'Versiunea de garanție a fost adăugată',
  'messages.success.categories.add': 'Categoria a fost adăugată',
  'messages.success.manufacturers.add': 'Producătorul a fost adăugat',

  'messages.error.loggedOut': 'Ai fost deconectat. Conectează-te pentru a continua.',
  'messages.error.records.add': 'Produsul nu a putut fi adăugat',
  'messages.error.tags.add': 'Eticheta nu a putut fi adăugată',
  'messages.error.tags.edit': 'Eticheta nu a putut fi salvată',
  'messages.error.serviceEntry.edit': 'Intrarea în service nu a putut fi salvată',
  'messages.error.warranties.add': 'Garanția nu a putut fi adăugată',
  'messages.error.warranties.edit': 'Garanția nu a putut fi salvată',
  'messages.error.warrantyVersion.add': 'Versiunea de garanție nu a putut fi adăugată',
  'messages.error.categories.add': 'Categoria nu a putut fi adăugată',
  'messages.error.manufacturers.add': 'Producătorul nu a putut fi adăugat',
};
