// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined';
import GroupOutlined from '@ant-design/icons/GroupOutlined';
import FolderOutlined from '@ant-design/icons/FolderOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import ToolOutlined from '@ant-design/icons/ToolOutlined';
import TeamOutlined from '@ant-design/icons/TeamOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import TagsOutlined from '@ant-design/icons/TagsOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined'

export default {
  SmileOutlined,
SettingOutlined,
FileDoneOutlined,
GroupOutlined,
FolderOutlined,
ShopOutlined,
ToolOutlined,
TeamOutlined,
UnorderedListOutlined,
TagsOutlined,
ProfileOutlined,
WarningOutlined
}
    