import component from './ro-RO/component';
import globalHeader from './ro-RO/globalHeader';
import menu from './ro-RO/menu';
import pages from './ro-RO/pages';
import pwa from './ro-RO/pwa';
import settingDrawer from './ro-RO/settingDrawer';
import settings from './ro-RO/settings';

export default {
  'navBar.lang': 'Limbi',
  'layout.user.link.help': 'Ajutor',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Termeni si conditii',
  'app.copyright.produced': 'Produs de Ascendia S.A.',
  'app.preview.down.block': 'Descarca aceasta pagina in proiectul local',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
};
