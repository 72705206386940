import { useIntl } from 'umi';
import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';

export default () => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    id: 'app.copyright.produced',
    defaultMessage: 'Produs de Ascendia SA',
  });
  const ver = '0.1022';
  const currentYear = new Date().getFullYear();
  return (
    <DefaultFooter
      copyright={`${currentYear} ${defaultMessage} - eGaranție v${ver}`}
      links={[
        {
          key: 'Termeni și condiții',
          title: 'Termeni și condiții',
          href: '/terms',
          blankTarget: true,
        },
        {
          key: 'Politica de confidențialitate a datelor (GDPR)',
          title: 'Politica de confidențialitate a datelor (GDPR)',
          href: '/privacy',
          blankTarget: true,
        },
      ]}
      style={{ background: '#fff' }}
    />
  );
};
