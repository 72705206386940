import {history, Link} from 'umi';
import { Result, Button } from 'antd';
import React from "react";

const Error403  = () => (
    <Result
        status="403"
        title="403"
        subTitle="Din pacate nu ai acces la aceasta pagina."
        extra={
            <Button type="primary" onClick={() => history.push('/')}>
                Inapoi acasa
            </Button>
        }
    />
);

export default Error403 ;
